<template>
  <div class="home" style="">
    <headert></headert>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/Entertainment.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb'/>
    <el-row class="CommerceAndTrade-topbox" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="WJgroup-titleBox-padding padding-tb70" style="">
          <div class="WJgroup-titleBox flex">

            <p class="WJgroup-title">{{ currentType.typename }}</p>
          </div>
        </div>
        <div class="flex CommerceAndTrade-top_msg">
          <div class="leftBox">
            <div class="leftBox_content" v-html="typeInfo.content">

            </div>
          </div>
          <div class="rightBox">
            <img :src="typeInfo.litpic" alt="">
          </div>
        </div>

      </el-col>
    </el-row>
    <el-row class="coreAdvantages" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <h1>{{ $t('entertainment.advantages') }} </h1>
        <p :class="lang=='en'?'letterS0':''" v-html="typeInfo.idea"></p>
      </el-col>
    </el-row>
    <el-row class="" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="cinamaDiv" v-for="(item,index) in newsList" :key="index">
          <h6>{{ item.title }}</h6>
          <div class="flex cinamaDmsg">
            <p class="flex"><img src="../../assets/image/Industry/img12.png" alt=""> {{ item.address }}</p>
            <p class="flex phone"><img src="../../assets/image/Industry/img13.png" alt=""> {{ item.phone }} </p>
          </div>
          <!--pc端-->
          <div class="cinamaDImg hidden-xs-only">
            <img class="cinamaDoneImg"
                 :src="item.showImg?item.showImg.image_url:(item.img_list&&item.img_list.length>0?item.img_list[0].image_url:'')"
                 alt="">
            <ul class="cinamaDul flex justify_center">
              <li v-for="(img,i) in item.img_list" :key="i" @click="choseImg(item,index,img)">
                <img :src="img.image_url" alt="">
              </li>
            </ul>
          </div>
          <!--         移动端 -->
          <div class="swiper-box hidden-sm-and-up">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="(slide,index) in item.img_list" :key="index">
                <!-- 这里是每个滑块的内容 -->
                <img :src="slide.image_url" >
              </swiper-slide>
              <!-- 如果需要分页器、导航等，可以在这里添加 -->
              <div class="swiper-pagination" slot="pagination"></div>
<!--              <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--              <div class="swiper-button-next" slot="button-next"></div>-->
            </swiper>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 底部 -->
    <div style="height: 150px;"></div>
    <footerb></footerb>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      menuActiveIndex: '/Entertainment',
      menu: {
        name: '产业布局',
        path: '/IndustryHouse',
        list: [{
          name: this.$t('menu.Property'),
          path: '/IndustryHouse',
        }, {
          name: this.$t('menu.park'),
          path: '/EntertainmentIndustrialPark',
        }, {
          name: this.$t('menu.entertainment'),
          path: '/Entertainment',
        }, {
          name: this.$t('menu.Commerce'),
          path: '/IndustryCommerce',
        }]
      },
      breadcrumb: [{
        name: this.$t('menu.layout'),
        path: '/IndustryHouse',
      }, {
        name: this.$t('menu.entertainment'),
        path: '/Entertainment',
      }, {
        name: this.$t('menu.cinema'),
        path: '/Entertainment',
      }],
      datalist: [],

      typeid: null,
      currentType: {},
      type1: {},
      type2: {},
      typeInfo: {},
      newsList: [],
      swiperOptions: {
        // 默认的Swiper配置
        autoplay:false,
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        loop: false,
        // 其他Swiper选项...
      }
    };
  },
  computed: {
    lang() {
      return window.localStorage.getItem('language')
    },
  },
  created() {
    this.typeid = this.$route.query.id;
    if (this.typeid == 156) {
      this.breadcrumb[2].name = this.$t('menu.Play')
    } else if (this.typeid == 157) {
      this.breadcrumb[2].name = this.$t('menu.education')
    }
    this.getTypeList();
  },
  methods: {
    getTypeList() {
      this.$init.getChannelList(channelList => {
        let typeList = channelList.filter(item => {
          return item.parent_id == this.typeid
        });
        this.currentType = channelList.find(item => {
          return item.id == this.typeid;
        });

        this.type1 = typeList.find(item => {
          return item.current_channel == 6;
        })
        this.type2 = typeList.find(item => {
          return item.current_channel != 6;
        })
        this.getData();
      })
    },
    getData() {
      let api = '?m=api&c=v1.Api&a=index&_ajax=1';
      this.$request.post(api, {
        apiType_1: `ekey=1&typeid=${this.type1.id}&addfields=content,content_en`,
        apiArclist_1: `ekey=1&typeid=${this.type2.id}&limit=100&addfields=address,phone,img_list,address_en,title_en,phone_en`,//获取栏目文章列表
      }).then(res => {
        this.typeInfo = this.$utils.translate(res.apiType[1].data);

        this.newsList = this.$utils.translate(res.apiArclist[1].data);

      })
    },
    choseImg(item, index, img) {
      item.showImg = img;
      this.newsList.splice(index, 1, item)
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    }
  }
};
</script>
<style lang="less" scoped>
.CommerceAndTrade-topbox {
  padding-bottom: 100px;
}

.leftBox {
  width: 50%;

}

.WJgroup-titleBox-padding {
  //padding-bottom: 40px;
  //padding-top: 107px;
}

.leftBox_content {
  padding-left: 20%;
  padding-right: 14%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #202E3A;
  line-height: 36px;
  text-indent: 2em;
}

.rightBox {

  height: 389px;
  width: 49%;
  overflow: hidden;

}

.rightBox img {
  width: 100%;
  height: 100%;
}

.coreAdvantages {
  background: url('../../assets/image/park/cinama2.png') no-repeat;
  background-size: cover;
  padding: 90px 0 110px;
}

.coreAdvantages h1 {
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 50px;
}

.coreAdvantages p {
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 3.5px;
  color: #FFFFFF;
}

.coreAdvantages .letterS0 {
  letter-spacing: 0px;
}

.cinamaDiv {
  margin-top: 90px;
}

.cinamaDiv h6 {
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.cinamaDiv .cinamaDmsg {
  margin-top: 19px;
  margin-bottom: 46px;
}

.cinamaDiv .cinamaDmsg .phone {
  min-width: 160px;

}

.cinamaDiv .cinamaDmsg p img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

.cinamaDiv .cinamaDmsg p {
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-right: 60px;
}

.cinamaDImg {
  width: 100%;
  position: relative;
  padding-top: 47%;
  overflow: hidden;
}

.cinamaDoneImg {
  width: 100%;
  // height: 100%;
  position: absolute;
  top: 10px;
}

.cinamaDul {
  position: absolute;
  bottom: 27px;
  left: 0;
  width: 100%;
  height: 90px;
}

.cinamaDul li {
  border: 1px solid #9E9E9E;
  width: 164px;
  height: 88px;
  margin: 0 8px;
  cursor: pointer;
}

.cinamaDul li img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .CommerceAndTrade-top_msg {
    display: block;

    .leftBox {
      width: 100%;

      .leftBox_content {
        padding: 0;
        font-size: 34px;
        line-height: 50px;
      }

    }

    .rightBox {
      margin-top: 30px;
      width: 100%;
    }
  }

  .coreAdvantages {
    padding: 50px 0;

    h1 {
      font-size: 39px;
    }

    p {
      font-size: 34px;
      line-height: 52px;
    }
  }

  .cinamaDiv {
    h6 {
      font-size: 39px;
      line-height: 50px;
      margin-bottom: 30px;
    }

    .cinamaDmsg {
      display: block;

      p {
        font-size: 34px;
        line-height: 52px;
        align-items: flex-start;
        margin-bottom: 10px;

        img {
          width: 44px;
          height: 44px;
        }
      }
    }
  }

  .WJgroup-titleBox-padding {
    padding-bottom: 40px !important;
  }
}
.swiper-box{
  height: 440px;
  width: 100%;
}
::v-deep .swiper-container{
  height: 100%!important;
  width: 100%!important;
  img{
    width: 100%;
    height: 100%;
  }
}
::v-deep .swiper-pagination-bullet-active{
  background-color: #aa8864;
  opacity: 1!important;
}
::v-deep .swiper-pagination-bullet{
  width: 20px!important;
  height: 20px!important;
  opacity: 0.5;
  //background-color: #fff!important;
}
</style>
